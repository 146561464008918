import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { userMappingApiActions } from './actions';
import { formatStringDate } from '../../../core/utils';

export interface State {
  userMappingConfigs: any[];
  loading: boolean;
  error: string;
  isUpdate: boolean;
  id: number;
}

export const initialState: State = {
  userMappingConfigs: [],
  loading: false,
  error: '',
  isUpdate: false,
  id: 0,
};

export const userMappingFeature = createFeature({
  name: 'userMapping',
  reducer: createReducer(
    initialState,
    on(userMappingApiActions.loadUserMappingConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      userMappingApiActions.loadUserMappingConfigSuccess,
      (state, { userMappingConfigs }) => ({
        ...state,
        userMappingConfigs,
        loading: false,
      })
    ),
    on(
      userMappingApiActions.loadUserMappingConfigFailure,
      (state, { error }) => ({
        ...state,
        error,
        loading: false,
      })
    ),
    on(userMappingApiActions.saveUserMappingConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      userMappingApiActions.saveUserMappingConfigSuccess,
      (state, { userMappingConfig }) => ({
        ...state,
        userMappingConfigs: [...state.userMappingConfigs, userMappingConfig],
        loading: false,
      })
    ),
    on(
      userMappingApiActions.saveUserMappingConfigFailure,
      (state, { error }) => ({
        ...state,
        error,
        loading: false,
      })
    ),
    on(userMappingApiActions.updateUserMappingConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      userMappingApiActions.updateUserMappingConfigSuccess,
      (state, { userMappingConfig }) => ({
        ...state,
        userMappingConfigs: state.userMappingConfigs.map((config) => {
          if (config.id === userMappingConfig.id) {
            return { ...config, ...userMappingConfig };
          }
          return config;
        }),
        id: 0,
        isUpdate: false,
        loading: false,
      })
    ),
    on(
      userMappingApiActions.updateUserMappingConfigFailure,
      (state, { error }) => ({
        ...state,
        error,
        loading: false,
      })
    ),
    on(userMappingApiActions.deleteUserMappingConfig, (state) => ({
      ...state,
      loading: true,
    })),
    on(
      userMappingApiActions.deleteUserMappingConfigSuccess,
      (state, { userMappingConfigId }) => ({
        ...state,
        userMappingConfigs: state.userMappingConfigs.filter(
          (config) => config.id !== userMappingConfigId
        ),
        loading: false,
      })
    ),
    on(
      userMappingApiActions.deleteUserMappingConfigFailure,
      (state, { error }) => ({
        ...state,
        error,
        loading: false,
      })
    ),
    on(userMappingApiActions.setUpdate, (state, { isUpdate, id }) => ({
      ...state,
      isUpdate,
      id,
    }))
  ),
});

export const {
  selectLoading,
  selectUserMappingConfigs,
  selectError,
  selectIsUpdate,
  selectId,
} = userMappingFeature;

export const sortedMappingConfigs = createSelector(
  selectUserMappingConfigs,
  (mappings) => {
    return mappings
      .map((mapping) => ({
        ...mapping,
        startDate: new Date(mapping.startDate),
        endDate: new Date(mapping.endDate),
      }))
      .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  }
);

export const selectUncoveredPeriods = createSelector(
  sortedMappingConfigs,
  (mappings) => {
    const uncoveredPeriods: string[] = [];
    let lastEndDate: Date | null = null;

    mappings.forEach((currentMapping, index) => {
      if (index === 0) {
        lastEndDate = currentMapping.endDate;
        return;
      }

      if (lastEndDate) {
        const oneDayAfterLastEndDate = new Date(lastEndDate);
        oneDayAfterLastEndDate.setDate(oneDayAfterLastEndDate.getDate() + 1);

        const oneDayBeforeCurrentStartDate = new Date(currentMapping.startDate);
        oneDayBeforeCurrentStartDate.setDate(
          oneDayBeforeCurrentStartDate.getDate() - 1
        );

        if (
          currentMapping.startDate.getTime() > oneDayAfterLastEndDate.getTime()
        ) {
          uncoveredPeriods.push(
            `${formatStringDate(
              oneDayAfterLastEndDate.toISOString()
            )} - ${formatStringDate(
              oneDayBeforeCurrentStartDate.toISOString()
            )}`
          );
        }
      }

      lastEndDate = currentMapping.endDate;
    });

    return uncoveredPeriods;
  }
);

export const selectUserMappingToEdit = createSelector(
  selectUserMappingConfigs,
  selectId,
  (mappings, id) => {
    return mappings.find((mapping) => mapping.id === id);
  }
);
