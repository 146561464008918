import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { UserMapping } from '@tdf/rtpca-models';

export const userMappingApiActions = createActionGroup({
  source: 'User Mapping Config API',
  events: {
    'Load User Mapping Config': emptyProps(),
    'Load User Mapping Config Success': props<{ userMappingConfigs: any[] }>(),
    'Load User Mapping Config Failure': props<{ error: string }>(),
    'Save User Mapping Config': props<{
      userMappingConfig: UserMapping;
      file: any;
    }>(),
    'Save User Mapping Config Success': props<{ userMappingConfig: any }>(),
    'Save User Mapping Config Failure': props<{ error: string }>(),
    'Update User Mapping Config': props<{ userMappingConfig: UserMapping }>(),
    'Update User Mapping Config Success': props<{ userMappingConfig: any }>(),
    'Update User Mapping Config Failure': props<{ error: string }>(),
    'Delete User Mapping Config': props<{
      userMappingConfigId: number;
      fileName: string;
    }>(),
    'Delete User Mapping Config Success': props<{
      userMappingConfigId: number;
    }>(),
    'Delete User Mapping Config Failure': props<{ error: string }>(),
    'Set Update': props<{ isUpdate: boolean; id: number }>(),
  },
});
